import React from 'react';
import { useConnectModal } from '@rainbow-me/rainbowkit';
import { useAccount, useSwitchChain } from 'wagmi';
import { type ChainId, parseEvmChainId } from '@/shared/assets/chains';
import { Button, Tooltip } from '@/shared/components';
import { type ButtonProps } from '@/shared/components/flip-ui-kit/Button';

const WalletActionButton = ({
  requireConnection,
  chainId,
  tooltip,
  ...props
}: {
  requireConnection: boolean;
  chainId: ChainId | undefined;
  tooltip?: string;
} & ButtonProps): JSX.Element => {
  const { isConnected, chain } = useAccount();
  const { switchChain } = useSwitchChain();
  const { openConnectModal } = useConnectModal();

  const srcTokenChainId = parseEvmChainId(chainId) || 1;

  let { children, onClick } = props;
  if (requireConnection && !isConnected) {
    children = 'Connect Wallet';
    onClick = openConnectModal ?? onClick;
  } else if (requireConnection && srcTokenChainId !== chain?.id) {
    children = 'Switch Network';
    onClick = () => switchChain?.({ chainId: srcTokenChainId });
  }

  return (
    <Tooltip content={tooltip} disabled={!tooltip}>
      <Button {...props} onClick={onClick}>
        {children}
      </Button>
    </Tooltip>
  );
};

export default WalletActionButton;
