import React from 'react';
import classNames from 'classnames';
import { Tooltip } from '@/shared/components';
import { ClockIcon } from '@/shared/icons/small';
import { formatToApproxTime } from '@/shared/utils';
import type { RouteResponse } from '../integrations';

export const DurationBadge = ({ route }: { route: RouteResponse }) => {
  const isBoosted = route.integration === 'chainflip' && route.integrationData.isBoosted;
  const formattedDuration =
    route.durationSeconds > 0 ? formatToApproxTime(route.durationSeconds) : undefined;
  const formattedDefaultDuration =
    route.integration === 'chainflip' && route.integrationData.defaultDurationSeconds > 0
      ? formatToApproxTime(route.integrationData.defaultDurationSeconds)
      : undefined;

  return (
    <div
      className={classNames(
        'flex items-center rounded-md border px-1 font-aeonikMono text-12',
        isBoosted
          ? 'cf-boost-active-gradient border-[#49293C] text-cf-pink-1'
          : 'border-cf-gray-4 bg-cf-gray-3 text-cf-light-3',
      )}
    >
      <Tooltip
        tooltipClassName="w-[306px]"
        content="Estimated time to receive, process, and send your funds to the destination address"
      >
        <div className="flex items-center gap-x-0.5 p-1">
          <ClockIcon />
          <span className="font-bold">{formattedDuration}</span>
          {isBoosted && formattedDefaultDuration && (
            <span className="pl-0.5 text-10 font-bold text-cf-light-2 line-through">
              {formattedDefaultDuration}
            </span>
          )}
        </div>
      </Tooltip>
    </div>
  );
};
