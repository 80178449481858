import type {} from '@redux-devtools/extension'; // required for devtools typing
import { create } from 'zustand';
import { devtools, persist } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';
import { isFillOrKillEnabled } from '@/shared/featureFlags';

type DepositMode = 'channel' | 'contract';

interface SettingsStore {
  boostEnabled: boolean;
  maxBoostFeeBpsSelected: number;
  _depositMode: DepositMode;
  fillOrKillEnabled: boolean;
  slippageTolerancePercent: number;
  swapDeadlineMinutes: number;
  toggleBoostEnabled: () => void;
  setBoostEnabled: (boostEnabled: boolean) => void;
  toggleFillOrKillEnabled: () => void;
  setSlippageTolerancePercent: (slippageTolerancePercent: number) => void;
  setSwapDeadlineMinutes: (swapDeadlineMinutes: number) => void;
  setDepositMode: (depositMode: DepositMode) => void;
}

const initialData = {
  boostEnabled: true,
  maxBoostFeeBpsSelected: 30, // Enabled by default unless user opted out
  _depositMode: 'channel',
  fillOrKillEnabled: isFillOrKillEnabled(),
  slippageTolerancePercent: 1,
  swapDeadlineMinutes: 15,
} as const;

const useSettingsStore = create<SettingsStore>()(
  devtools(
    persist(
      immer((set) => ({
        ...initialData,
        toggleBoostEnabled: () =>
          set((state) => ({
            boostEnabled: !state.boostEnabled,
            maxBoostFeeBpsSelected: !state.boostEnabled ? 30 : 0,
          })),
        setBoostEnabled: (boostEnabled) => set({ boostEnabled }),
        toggleFillOrKillEnabled: () =>
          set((state) => ({ fillOrKillEnabled: !state.fillOrKillEnabled })),
        setSlippageTolerancePercent: (slippageTolerancePercent) =>
          set({ slippageTolerancePercent }),
        setSwapDeadlineMinutes: (swapDeadlineMinutes) => set({ swapDeadlineMinutes }),
        setDepositMode: (depositMode) => set({ _depositMode: depositMode }),
      })),
      {
        name: 'cf.swap-settings',
        version: 1,
      },
    ),
  ),
);

export const selectDepositMode = (state: SettingsStore) => {
  if (state.fillOrKillEnabled) return 'channel';
  return state._depositMode; // eslint-disable-line no-underscore-dangle
};

export default useSettingsStore;
