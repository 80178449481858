import classNames from 'classnames';
import { ArrowIcon, ScrollIcon, RouteIcon } from '@/shared/icons/large';
import { TokenAmount, chainflipAssetMap } from '@/shared/utils';
import {
  assetConstants,
  chainConstants,
  type ChainflipAsset,
  type ChainflipChain,
} from '@/shared/utils/chainflip';
import { TokenWithChain } from './TokenWithChain';

export const LogoWithAmount = ({
  chainLogo,
  tokenLogo,
  displayChainLogo = true,
  amount,
  disabled = false,
}: {
  chainLogo: (props?: React.SVGProps<SVGSVGElement>) => JSX.Element;
  tokenLogo: (props?: React.SVGProps<SVGSVGElement>) => JSX.Element;
  displayChainLogo?: boolean;
  amount?: string;
  disabled?: boolean;
}) => (
  <div className={classNames('flex items-center space-x-2', disabled && 'opacity-30')}>
    {displayChainLogo ? (
      <TokenWithChain chainLogo={chainLogo} tokenLogo={tokenLogo} />
    ) : (
      tokenLogo({ width: 19, height: 19 })
    )}
    <div className="font-aeonikMono text-12 text-cf-white">{amount}</div>
  </div>
);

type RouteStep = {
  inChain: ChainflipChain;
  outChain: ChainflipChain;
  inAsset: ChainflipAsset;
  outAsset: ChainflipAsset;
  inAmount: TokenAmount;
  outAmount: TokenAmount;
  displayUsdcChainLogo: boolean;
};

type Swap = {
  depositAmount: string;
  destinationAsset: ChainflipAsset;
  egressAmount?: string | null | undefined;
  intermediateAmount?: string | null | undefined;
  sourceAsset: ChainflipAsset;
};

const RouteTooltip = ({ swap, type }: { swap: Swap; type: 'Route' | 'Order' }) => {
  const sourceChain = assetConstants[swap.sourceAsset].chain;
  const destinationChain = assetConstants[swap.destinationAsset].chain;
  const depositAmount = TokenAmount.fromAsset(swap.depositAmount, swap.sourceAsset);
  const intermediateAmount = TokenAmount.fromAsset(swap.intermediateAmount, 'Usdc');
  const egressAmount = TokenAmount.fromAsset(swap.egressAmount, swap.destinationAsset);

  const routeSteps = [] as RouteStep[];

  if (depositAmount?.gt(0) && intermediateAmount?.gt(0) && egressAmount) {
    routeSteps.push(
      {
        inChain: sourceChain,
        outChain: 'Ethereum',
        inAsset: swap.sourceAsset,
        outAsset: 'Usdc',
        inAmount: depositAmount,
        outAmount: intermediateAmount,
        displayUsdcChainLogo: false,
      },
      {
        inChain: 'Ethereum',
        outChain: destinationChain,
        inAsset: 'Usdc',
        outAsset: swap.destinationAsset,
        inAmount: intermediateAmount,
        outAmount: egressAmount,
        displayUsdcChainLogo: false,
      },
    );
  } else if (depositAmount?.gt(0) && egressAmount) {
    routeSteps.push({
      inChain: sourceChain,
      outChain: destinationChain,
      inAsset: swap.sourceAsset,
      outAsset: swap.destinationAsset,
      inAmount: depositAmount,
      outAmount: egressAmount,
      displayUsdcChainLogo: true,
    });
  }

  return (
    <div className="flex min-w-[280px] flex-col space-y-4 p-2">
      <div className="flex items-center space-x-1 text-cf-white">
        {type === 'Order' ? <ScrollIcon /> : <RouteIcon />}
        <div className="font-aeonikMedium text-16">{type} Details</div>
      </div>
      {routeSteps.map((step) => (
        <div key={step.inAsset} className="flex flex-col space-y-4">
          <div key={step.inAmount?.toFixedDisplay()} className="flex items-center space-x-2">
            <LogoWithAmount
              chainLogo={chainConstants[step.inChain].logo}
              tokenLogo={assetConstants[step.inAsset].logo}
              amount={step.inAmount?.toFixedDisplay()}
              displayChainLogo={step.inAsset !== 'Usdc' || step.displayUsdcChainLogo}
            />
            <ArrowIcon width={16} className="text-cf-light-2" />
            <LogoWithAmount
              chainLogo={chainConstants[step.outChain].logo}
              tokenLogo={assetConstants[step.outAsset].logo}
              amount={step.outAmount?.toFixedDisplay()}
              displayChainLogo={step.outAsset !== 'Usdc' || step.displayUsdcChainLogo}
            />
          </div>
          <div className="text-12 text-cf-light-3">
            <div>
              {`Swap ${step.inAmount?.toFixedDisplay()} ${
                chainflipAssetMap[step.inAsset].symbol
              } for ${step.outAmount?.toFixedDisplay()} ${
                chainflipAssetMap[step.outAsset].symbol
              } on Chainflip`}
            </div>
          </div>
        </div>
      ))}
      <div className="text-12 text-cf-light-3">
        {depositAmount.gt(0) &&
          egressAmount?.gt(0) &&
          `Rate: 1 ${chainflipAssetMap[swap.sourceAsset].symbol} ≈ ${egressAmount
            .ratio(depositAmount)
            .toFixed(8)} ${chainflipAssetMap[swap.destinationAsset].symbol}`}
      </div>
    </div>
  );
};

export default RouteTooltip;
